import { Injectable } from "@angular/core";
import { GlobalService } from "../services/global.service";
import { Router } from "@angular/router";
import { MembershipService } from "../../pages/membership/membership.service";

@Injectable({providedIn: 'root'})
export class AuthGuardService {

    constructor(
        private router: Router,
        private membershipService: MembershipService
    ) {}

    public isLogin() {
        if (!this.membershipService.isLoggedIn) {
            this.router.navigate(['/membership/login']); // 사용자가 인증되지 않은 경우, 로그인 페이지로 리디렉션
            return false;
        }
        return true;
    }

    // 로그인 상태에서 로그인 페이지 접근을 방지하는 메서드
    public isLoggedOut() {
        // if (this.globalService.currentUser.isLogin()) {
        //     this.router.navigate(['/chatbot']); // 로그인 상태인 경우, 홈페이지로 리디렉션
        //     return false;
        // }
        return true;
    }
    
}